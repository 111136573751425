import React from "react"
import Seo from "src/utils/seo"
import Layout from "components/Layout/Layout"
import SettingsManager from "components/SettingsManager/SettingsManager"
import AdGroup from "components/AdGroup/AdGroup"

export default function Page() {
    return(
        <React.Fragment>
            <Seo title="AdGroups" />
            <Layout showActions={true}>
                <AdGroup page="adgroup" />
            </Layout>
            <SettingsManager/>
        </React.Fragment>
    )
}